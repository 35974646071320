/* Import Semantic UI styles */
@import url('https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/semantic.min.css');

/* Override font-related styles */
.ui.button,
.ui.input,
.ui.header,
.ui.text,
.ui.form,
.ui.message {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
} 